<template>
  <b-row>
      <b-col cols="12">
          <b-form-group :label="$t('term')">
              <b-input-group >
                  <div class="label-as-input">{{ isNotNullValue(formData.term) }}</div>
              </b-input-group>
          </b-form-group>
      </b-col>
      <b-col cols="12">
          <b-form-group :label="$t('definition')">
              <b-input-group >
                  <div class="label-as-input">{{ isNotNullValue(formData.definition) }}</div>
              </b-input-group>
          </b-form-group>
      </b-col>
      <b-col cols="12">
          <b-form-group :label="$t('reference')">
              <b-input-group >
                  <div class="label-as-input">{{ isNotNullValue(formData.reference) }}</div>
              </b-input-group>
          </b-form-group>
      </b-col>
  </b-row>
</template>

<script>
// Services
import GlossaryServices from '@/services/GlossaryServices';

export default {
    props: {
        formId: {
            type: Number
        }
    },
    data() {
        return {
            formData: {
                term: null,
                definition: null,
                reference: null
            },
            formLoading: false
        }
    },
    watch: {
        formId: function (val) {
            this.get(val)
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            GlossaryServices.get(id)
                .then(response => {
                    let data = response.data.data;
                    this.formData = {
                        term: data.term,
                        definition: data.definition,
                        reference:data.reference
                    }
                })
        },
    }
}
</script>

